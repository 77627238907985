var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-max"},[(_vm.ifLoad)?_c('div',{staticClass:"col-not-reverse"},[_c('HeaderRithual',{attrs:{"valueNav":_vm.navValue}}),_c('div',{staticClass:"w-100 bg_gray_gradient pt-3"},_vm._l((_vm.mainImg),function(img){return _c('div',{key:img.id},[(img.display_on)?_c('div',{staticClass:"bg_block_reverse_double pb-5"},[_c('div',{staticClass:"container lead pd-10",class:{
              'f-italic': img.font === 'К',
              'f-bold': img.font === 'Ж',
              'f-bold f-italic': img.font === 'ЖК',
            }},[_c('blockquote',{staticClass:"pb-5 pt-5"},[_vm._v(" "+_vm._s(img.description_site)+" ")])])]):_vm._e(),_c('div',[_c('img',{staticClass:"w-100",attrs:{"src":img.image,"alt":"image"}})]),(img.display_on_list)?_c('div',{staticClass:"bg_block"},[_c('div',{staticClass:"container",class:{
              'f-italic': img.font === 'К',
              'f-bold': img.font === 'Ж',
              'f-bold f-italic': img.font === 'ЖК',
            },domProps:{"innerHTML":_vm._s(img.header_image)}})]):_vm._e()])}),0),_c('FooterRithual')],1):_c('div',[_c('SpinnerComponent')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }